import { graphql } from 'gatsby';
import React from 'react';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import PricingGrid from '~components/common/PricingGrid';
import Layout from '~components/layouts/Default';
import Quiz from '~components/quiz/Quiz';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import ImageVideo from '~components/common/ImageVideo';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Was gegen Stress hilft und wie du entspannst" desc="Im Kursquiz findest du heraus, welches Verfahren dir gegen den Stress hilft" active="Kursquiz">
      <div className="bg-gray-50">
        <PageHeader
          title="Kursquiz"
          breadcrumb={[
            {
              title: 'Kursquiz',
              link: '/kurse/kursquiz/',
              current: true
            }
          ]}
        />
        <div className="overflow-hidden pb-16">
          <Container noMargin className="relative " dotsRight>
            <div className="mb-8 text-center">
              <H1>Du weißt nicht, welcher Kurs der Richtige für dich ist?</H1>
              <H2 className="mb-5">Dann mache einfach unseren Test!</H2>
              <div className="mx-auto my-8 max-w-xl">
                <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1647337643/kunden/entspannungshelden/kursquiz_hrs6so.mp4" image={data.videCover.childImageSharp} alt="Welcher Kurs passt zu Dir?" />
              </div>
              <div className="mx-auto max-w-2xl p-6">
                <Quiz />
              </div>
            </div>
            <div className="mx-auto mt-5 max-w-2xl">
              <P className="mb-3">
                Das wichtigste zum Schluss. Unsere Kurse sind alle nach §20 SGB V zertifiziert, das bedeutet für dich, dass du mit uns einen qualitätsgeprüften Anbieter gefunden hast, dessen Kursangebot von den gesetzlichen Krankenkassen
                bezuschusst wird. Wie hoch die Förderung ausfällt, ist sehr unterschiedlich. Um dir den Zugang zu erleichtern und deine Frage auf die Förderhöhe zu beantworten, hat unser lieber Andreas Lautenschlager ein Tool entwickelt,
                dass du hier unter dem Krankenkassencheck findest. Gib einfach ein, bei wem du versichert bist und du erhältst deine Förderhöhe für das Jahr 2022.
              </P>
            </div>
          </Container>
        </div>

        <div className="overflow-hidden">
          <Container className="relative">
            <PricingGrid />
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
        }
      }
    }
    videCover: file(relativePath: { eq: "video/kursquiz.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
